/* HabitsPage.module.css */

.container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .header {
    font-size: 1.8rem;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .list {
    list-style-type: none;
    padding: 0;
  }
  
  .listItem {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }
  
  .button {
    margin-left: 8px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  .input {
    padding: 8px;
    width: calc(100% - 20px);
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }
  
  .actionButtons {
    display: flex;
    gap: 8px;
  }