.container {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 90vh;
    background-color: white;
    box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
    z-index: 1000;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    outline: none;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.container[data-closed="true"] {
    width: 100%;
    height: 80px;
}

.contentWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    outline: none;
    display: flex;
    flex-direction: column;
}

.minimizeButton {
    position: absolute;
    top: 24px;
    right: 60px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #666;
    z-index: 1001;
}

.contentContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-top: 40px;
}

.typeButton {
    color: #333;
    text-decoration: none;
    padding: 0.5rem 1.25rem;
    font-size: 0.95rem;
    font-weight: 400;
    letter-spacing: 0.3px;
    transition: all 0.2s ease;
    position: relative;
    margin: 0 0.25rem;
    background: transparent;
    border: none;
    cursor: pointer;
}

.typeButton:after {
    content: '';
    position: absolute;
    bottom: -1px;
    left: 1.25rem;
    right: 1.25rem;
    height: 1px;
    background: rgba(0, 0, 0, 0.2);
    transform: scaleX(0);
    transition: transform 0.2s ease;
}

.typeButton:hover {
    color: #000;
}

.active {
    color: #333;
}

.active:after {
    transform: scaleX(1);
}

.typeToggleContainer {
    display: flex;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.formContainer {
    max-width: 64rem;
    margin-left: auto;
    margin-right: auto;
    margin-top: 1rem;
    width: 100%;
}

.textarea {
    width: 100%;
    flex: 1;
    resize: none;
    padding: 1rem;
    margin-top: 1rem;
    border: 1px solid #E5E7EB;
    border-radius: 0.5rem;
    outline: none;
    background-color: transparent;
    height: 300px;
}
