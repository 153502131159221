#checkMarkTable-schedule td {
    width: 10vw;
    height:5vw;
}

#checkMarkTable-schedule td.day {
    /* background-color: red; */
    width: 10vw;
    height:5vw;
    border:1px black solid;
}

.entry {
    margin:20px;
}

.active {
    background-color: green;
    color: white;
    padding: 10px;
  }
  
  .complete {
    text-decoration:line-through;
  }

.floating-element {
    text-align: center;
    font-size: 28px;
    position: sticky;
    top: 0px;
    left: 0;
    width: 100%;
    background-color: lightblue;
    padding: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}
#container {
    position: relative;
    width: 100%;
    height: 70vh; /* Adjust height as needed */
    overflow: auto; /* Enable scrolling within the container */
    border: 1px solid #ccc;
}

.container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

.loginContainer {
  display: flex;
  align-items: center; /* Vertically align items */
  gap: 10px; /* Optional: Adds spacing between the elements */
  float: right;
  margin-right:2em;
  }

  .login-logout {
    color:blue;
  }
  
  .header {
    font-size: 1.5rem;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .list {
    list-style-type: none;
    padding: 0;
  }
  
  .listItem {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ccc;
  }

  .button {
    padding: 10px 20px;
    margin-right:10px;
    margin-bottom: 20px;
    font-size: 16px;
    cursor: pointer;
  }

  .button.on {
    background-color: blueviolet;
    color: white;
  }
  
  .button:hover {
    background-color: #0056b3;
  }

  .button:hover.on {
    background-color: blueviolet;
  }
  
  .input {
    padding: 8px;
    width: calc(100% - 20px);
    border-radius: 4px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }
  .chart-container {
    width: 500; /* Container width, fixed */
    overflow-x: auto; /* Enable horizontal scroll */
    padding-left: 40px; /* Offset for the fixed Y axis */
  }
  
  .scroll-wrapper {
    display: flex;
  }
  